/* eslint-disable react/no-array-index-key */
import cn from '@propertypal/shared/src/utils/cn';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Container, Text } from './Button.style';

export interface Props {
  ariaLabel?: string;
  containerStyle?: CSSProperties;
  color?: string;
  hoverColor?: string;
  fontColor?: string;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  icon?: any;
  testID?: string;
  loadingTestID?: string;
  loading?: boolean;
  maxWidth?: string;
  type?: 'button' | 'submit' | 'reset';
  hoverTextColor?: string;
  disabled?: boolean;
  disabledColor?: string;
  disabledStyling?: string;
  skinnyMode?: boolean;
  pulse?: boolean;
  children?: ReactNode;
  fontSize?: number;
  className?: string;
  disableClassName?: boolean;
}

const Button: FunctionComponent<Props> = (props) => {
  return (
    <Container
      aria-label={props.ariaLabel}
      $pulsing={props.pulse}
      className={cn(props.className, props.disableClassName ? '' : 'pp-button')}
      $buttonDisabled={props.disabled}
      disabled={props.disabled}
      onClick={(e) => {
        if (!props.disabled && !props.loading && props.onClick) props.onClick(e);
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      color={props.color}
      data-testid={props.testID}
      $hoverColor={props.hoverColor}
      style={props.containerStyle}
      type={props.type}
      $maxWidth={props.maxWidth}
      $hoverTextColor={props.hoverTextColor}
      $disabledColor={props.disabledColor}
      $disabledStyling={props.disabledStyling}
      $skinnyMode={props.skinnyMode}
      tabIndex={!props.onClick && props.type === 'button' ? -1 : undefined}
    >
      {!props.loading && (
        <>
          {props.icon && <FontAwesomeIcon icon={props.icon} color="white" size={18} style={{ marginRight: 10 }} />}

          <Text $fontColor={props.fontColor} className="buttonText" $fontSize={props.fontSize}>
            {props.children}
          </Text>
        </>
      )}

      {props.loading && (
        <div data-testid={props.loadingTestID || `${props.testID}Loading`}>
          <ScaleLoader color="#ffffff" height={20} width={3} />
        </div>
      )}
    </Container>
  );
};

export default Button;
