/* eslint-disable max-len */
import OgImage from '@propertypal/shared/src/resources/images/og-image-sitewide.jpeg';
import { MetaDescriptions } from '@propertypal/shared/src/types';
import React, { Fragment } from 'react';

interface LatLng {
  latitude?: number;
  longitude?: number;
}

interface Props {
  title: string;
  url: string;
  description?: string;
  nextUrl?: string | null;
  prevUrl?: string | null;
  shortLink?: string;
  image?: {
    url: string;
    width: number;
    height: number;
  };
  type?: string;
  updatedTime?: string;
  latLng?: LatLng;
  accountNumber?: string;
  twitterExtraData?: MetaDescriptions['twitterExtraData'];
  robots?: string;
}

export const BASE_URL = 'https://www.propertypal.com';
export const DEFAULT_DESCRIPTION =
  'Browse Through More Than 60,000 Properties For Sale And Rent In Ireland And Northern Ireland. ' +
  'PropertyPal Is Your Best Friend In Property.';
export const DEFAULT_TYPE = 'website';
export const DEFAULT_ROBOTS = 'noodp';

const DEFAULT_IMAGE = {
  url: `${BASE_URL}${OgImage.src}`,
  width: '1200',
  height: '630',
};

const generateMetaTags = (props: Props) => {
  const url = `${BASE_URL}${props.url}`;
  const description = props.description || DEFAULT_DESCRIPTION;
  const image = props.image || DEFAULT_IMAGE;
  const type = props.type || DEFAULT_TYPE;
  const robots = props.robots || DEFAULT_ROBOTS;

  // @ts-ignore
  return (
    <>
      <title>{props.title}</title>
      {/* eslint-disable-next-line react/no-invalid-html-attribute */}
      {!!props.shortLink && <link rel="shortlink" href={props.shortLink} />}
      {!!props.nextUrl && <link key="link:next" rel="next" href={`${BASE_URL}${props.nextUrl}`} />}
      {!!props.prevUrl && <link key="link:prev" rel="prev" href={`${BASE_URL}${props.prevUrl}`} />}

      <link key="link:canonical" rel="canonical" href={url} />
      <meta name="description" content={description} />

      {props.accountNumber && <meta itemProp="pp-gaListingAccountNumber" content={props.accountNumber} />}

      {props.latLng && (
        <>
          {props.latLng.latitude && (
            <meta property="place:location:latitude" content={props.latLng.latitude.toString()} />
          )}
          {props.latLng.longitude && (
            <meta property="place:location:longitude" content={props.latLng.longitude.toString()} />
          )}
        </>
      )}

      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={description} />
      <meta key="twitter:image" name="twitter:image" content={image.url} />

      {/* Twitter Extra Data */}
      {props.twitterExtraData &&
        Object.keys(props.twitterExtraData).map((d, i) => (
          <Fragment key={d}>
            <meta property={`twitter:label${i + 1}`} content={d} />
            <meta property={`twitter:data${i + 1}`} content={props.twitterExtraData ? props.twitterExtraData[d] : ''} />
          </Fragment>
        ))}

      <meta key="og:title" property="og:title" content={props.title} />
      <meta key="og:url" property="og:url" content={url} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:type" property="og:type" content={type} />
      <meta key="og:image" property="og:image" content={image.url} />
      <meta key="og:image:type" property="og:image:type" content="image/jpeg" />
      <meta key="og:image:width" property="og:image:width" content={image.width.toString()} />
      <meta key="og:image:height" property="og:image:height" content={image.height.toString()} />
      {props.updatedTime && <meta property="og:updated_time" content={props.updatedTime} />}
      {props.robots && <meta name="robots" content={robots} />}
    </>
  );
};

export default generateMetaTags;
